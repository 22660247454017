* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  height: 100vh;
  width: 100vw;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html, body {
  height: 100%;
}
